.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button{
  padding: 8px;
}

.container{
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 350px;
}

.list-name{
  padding: 12px;
  text-align: center;
  border: grey 1px solid;
  background: lightgray;
  margin-bottom: 5px;
  width: 100%;

  cursor: pointer;
}

.list-name__wrapper{
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  /* max-width: 200px; */
  /* width: 100%; */
  padding: 15px;

  /* justify-content: center; */
  align-items: center;
}

.list-wrapper--disabled, .list-name__wrapper--disabled{
  opacity: 0.6;
  pointer-events: none;
  user-select: none;
}

.random-item{
  padding: 30px 12px;
  font-weight: 700;
  font-size: 30px;
}